import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Cache-cache" />
    <h3 className='underline-title'>Cache-cache</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Tu le vois courir <br />
        haletant<br />
        Muni de foi authentique <br />
        Prêt à voler au "yambi papa" !(1)<br />
        Il se croyait être du milieu<br />
        Il croyait t'accueillir <br />
        mains blanches <br />
      </p>
      <p>
        Il ne comprenait pas <br />
        - que le mineur ait la mine salie!<br />
        - comment le voleur avait le coeur grillé!<br />
        - et l'assassin gardait les mains nègres!<br />
      </p>
      <p>
        Il s'approchait et tu t'éloignais <br />
        Il prenait distance et tu t'approchais <br />
        Sans parole aucune <br />
        Il lui était manifesté <br />
        Qu'il n'était qu'un métèque!<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(1) Une manière d'hospitalité africaine, propre aux bantous usant de la 
    langue lingala; équivalant à pres au "Karibu" swahili des citoyens de l'Est de la République Démocratique
    du Congo</p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
